<template>
  <div :key="settingsComponent">
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10" class="d-flex align-items-center">
        <h3>
          {{ $t('settings.folder.corporateIdentity.name') }}
        </h3>
        <b-dropdown
            class="context-menu"
            toggle-class="dot-button"
            no-caret
            no-flip
            right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="copySettings">
            {{ $t('dropdowns.copySettings') }}
          </b-dropdown-item>
          <b-dropdown-item
              v-if="settingsCopied"
              @click="pasteSettings"
          >
            {{ $t('dropdowns.pasteSettings') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="override-children" :class="(toggleAllCiChildrenOverride) ? 'expanded' : ''">
          <h4
            class="px-4 py-3 m-0 text-uppercase d-flex justify-content-between align-items-center"
          >
            <span>{{ $t('settings.folder.childrenOverride.label') }}</span>
            <b-form-checkbox
              id="toggle-all-children-override"
              v-model="toggleAllCiChildrenOverride"
              name="toggle-all-children-override"
              size="lg"
              :value="true"
              :unchecked-value="false"
              @change="toggleOverrideAllChildrenBox($event)"
            >
            </b-form-checkbox>
          </h4>
          <b-collapse id="collapse-override-ci-children">
            <div class="px-4 py-3">
              <p v-html="$t('settings.folder.childrenOverride.desc')"></p>
              <ul class="pl-0">
                <li>
                  <b-form-checkbox
                    id="override-all-ci-children"
                    v-model="overrideAllCiChildren"
                    name="overrideAllCiChildren"
                    size="lg"
                    :value="true"
                    :unchecked-value="false"
                    @change="overrideAllChildren($event)"
                  >
                    {{ "All CI Settings" }}
                  </b-form-checkbox>
                  <ul>
                    <li v-for="ciItem in allChildrenCisArray" :key="ciItem.handle">
                      <b-form-checkbox
                        :id="ciItem.handle"
                        v-model="overrideChildrenCis[ciItem.handle]"
                        :name="ciItem.handle"
                        size="lg"
                        :value="true"
                        :unchecked-value="false"
                        @change="changeOverrideChildren(ciItem.name, $event)"
                      >
                        {{ $t(`settings.folder.childrenOverride.${ciItem.handle}Label`) }}
                      </b-form-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.folder.corporateIdentity.logoLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="corporateIdentity.logo.image"
          @upload-image-object="updateUploadImageObject"
          :allowImageEdit="true"
          :allowImageFocalpointEdit="true"
        />
        <b-form-text
          v-html="$t('settings.folder.corporateIdentity.logoDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.folder.corporateIdentity.faviconLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="corporateIdentity.favicon.image"
          @upload-image-object="updateUploadFaviconImageObject"
          :accepted-file-types="'image/x-icon,image/vnd.microsoft.icon,'+
          'image/ico,image/icon,text/ico,application/ico'"
          :file-poster-height="'44px'"
          :allowImageEdit="false"
          :allowImageFocalpointEdit="false"
        />
        <b-form-text
          v-html="$t('settings.folder.corporateIdentity.faviconDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.corporateIdentity.colors') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker :color="corporateIdentity._customColor[0]" @update-color="updateBaseColor"/>
          <label class="pl-3">
            {{ $t('settings.folder.corporateIdentity.baseColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.folder.corporateIdentity.baseColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[1]"
            @update-color="updateSecondColor"/>
          <label class="pl-3">
            {{ $t('settings.folder.corporateIdentity.secondColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.folder.corporateIdentity.secondColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[2]"
            @update-color="updateThirdColor"
          />
          <label class="pl-3">
            {{ $t('settings.folder.corporateIdentity.thirdColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.folder.corporateIdentity.thirdColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[3]"
            @update-color="updateFourthColor"/>
          <label class="pl-3">
            {{ $t('settings.folder.corporateIdentity.fourthColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.folder.corporateIdentity.fourthColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.corporateIdentity.typographyLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <label class="mb-0">
          {{ $t('settings.folder.corporateIdentity.fontOneLabel') }}
        </label>
        <b-form-text class="pb-3">
          {{ $t('settings.folder.corporateIdentity.fontOneDesc') }}
        </b-form-text>
        <b-form-group v-slot="{ ariaDescribedbyFontOne }">
          <b-form-radio
            v-model="fontOneSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontOne"
            name="fontOneSource"
            value="googleFonts"
            @change="changeFontSource('googleFonts', 1)"
          >
            {{ $t('settings.folder.corporateIdentity.useGoogleFonts') }}
          </b-form-radio>
          <b-form-radio
            v-model="fontOneSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontOne"
            name="fontOneSource"
            value="systemFonts"
            @change="changeFontSource('systemFonts', 1)"
          >
            {{ $t('settings.folder.corporateIdentity.useSystemFonts') }}
          </b-form-radio>
          <b-form-radio
            v-if="customGroupFonts.length > 0"
            v-model="fontOneSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontOne"
            name="fontOneSource"
            value="customFonts"
            @change="changeFontSource('customFonts', 1)"
          >
            {{ $t('settings.folder.corporateIdentity.useCustomFonts') }}
          </b-form-radio>
        </b-form-group>
        <div v-if="fontOneSource === 'customFonts'">
          <div style="width: 200px;">
            <b-form-select
              v-model="fontOne"
              :options="customGroupFonts"
              @change="changeFont($event,1)"
            ></b-form-select>
          </div>
        </div>
        <div
          v-if="fontOneSource === 'systemFonts'"
          class="d-flex align-items-center"
        >
          <div style="width: 200px;">
            <b-form-select
              v-model="fontOne"
              :options="fontFamilyListSystem"
              @change="changeFont($event,1)"
            ></b-form-select>
          </div>
        </div>
        <div
          v-if="fontOneSource === 'googleFonts'"
          class="d-flex align-items-center"
        >
          <font-picker
            id="font-picker-one"
            ref="font-picker-one"
            class="mb-2"
            :api-key="'AIzaSyCBBJElCVDbMFMth4hmBGIAzDp5bO8ozWY'"
            :active-font="fontOne"
            @change="changeFont($event,1)"
          >
          </font-picker>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4 pb-4">
      <b-col cols="12" offset-sm="1" sm="3"></b-col>
      <b-col cols="12" sm="7">
        <label class="mb-0">
          {{ $t('settings.folder.corporateIdentity.fontTwoLabel') }}
        </label>
        <b-form-text class="pb-3">
          {{ $t('settings.folder.corporateIdentity.fontTwoDesc') }}
        </b-form-text>
        <b-form-group v-slot="{ ariaDescribedbyFontTwo }">
          <b-form-radio
            v-model="fontTwoSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontTwo"
            name="fontTwoSource"
            value="googleFonts"
            @change="changeFontSource('googleFonts', 2)"
          >
            {{ $t('settings.folder.corporateIdentity.useGoogleFonts') }}
          </b-form-radio>
          <b-form-radio
            v-model="fontTwoSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontTwo"
            name="fontTwoSource"
            value="systemFonts"
            @change="changeFontSource('systemFonts', 2)"
          >
            {{ $t('settings.folder.corporateIdentity.useSystemFonts') }}
          </b-form-radio>
          <b-form-radio
            v-if="customGroupFonts.length > 0"
            v-model="fontTwoSource"
            class="mb-1 text-uppercase"
            :aria-describedby="ariaDescribedbyFontTwo"
            name="fontTwoSource"
            value="customFonts"
            @change="changeFontSource('customFonts', 2)"
          >
            {{ $t('settings.folder.corporateIdentity.useCustomFonts') }}
          </b-form-radio>
        </b-form-group>
        <div v-if="fontTwoSource === 'customFonts'">
          <div style="width: 200px;">
            <b-form-select
              v-model="fontTwo"
              :options="customGroupFonts"
              @change="changeFont($event,2)"
            ></b-form-select>
          </div>
        </div>
        <div
          v-if="fontTwoSource === 'systemFonts'"
          class="d-flex align-items-center"
        >
          <div style="width: 200px;">
            <b-form-select
              v-model="fontTwo"
              :options="fontFamilyListSystem"
              @change="changeFont($event,2)"
            ></b-form-select>
          </div>
        </div>
        <div
          v-if="fontTwoSource === 'googleFonts'"
          class="d-flex align-items-center"
        >
          <font-picker
            id="font-picker-two"
            ref="font-picker-two"
            class="mb-2"
            :api-key="'AIzaSyCBBJElCVDbMFMth4hmBGIAzDp5bO8ozWY'"
            :active-font="fontTwo"
            @change="changeFont($event,2)"
          >
          </font-picker>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <div class="mb-4">
          <b-form-checkbox
            id="enable-typo"
            v-model="corporateIdentity.enableExtendTypo"
            name="enableExtendTypo"
            size="lg"
            :value="true"
            :unchecked-value="false"
            @change="enableDisableExtendTypo"
          >
            {{ $t('settings.folder.corporateIdentity.typography.enableExtendTypoLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('settings.folder.corporateIdentity.typography.enableExtendTypoDec') }}
          </b-form-text>
        </div>
        <div v-if="corporateIdentity.enableExtendTypo" class="typography-box">
          <h3 class="mb-2" @click="openTypographyModal('h1')">
            Headline 1 (h1)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h2')">
            Headline 2 (h2)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h3')">
            Headline 3 (h3)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h4')">
            Headline 4 (h4)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h5')">
            Headline 5 (h5)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h6')">
            Headline 6 (h6)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('p')">
            Paragraph (p)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
        </div>
        <modal-typography-settings
          v-if="showTypographyModal"
          :tag="tagForTypography"
          :first-font-family="fontOne"
          :second-font-family="fontTwo"
          :current-selected-family="familyOfExtendedTypoTag(tagForTypography)"
          :typographyStyles="typographyStyles"
          @update-styles="updateStyles"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.folder.corporateIdentity.buttonLayoutLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <div>
          <div style="height: 50px;">
            <b-button :style="corporateIdentity.buttons.styleObject">Button</b-button>
          </div>
          <b-form-group v-slot="{ ariaDescribedbyShape }">
            <b-form-radio-group
              id="radio-shape-options"
              v-model="corporateIdentity.buttons.layout.buttonShape"
              :options="selectedButtonShapeOptions"
              :aria-describedby="ariaDescribedbyShape"
              name="radio-shape-options"
              @change="buttonShapeChanged($event, 'buttonShape')"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group v-slot="{ ariaDescribedbyStyle }">
            <b-form-radio-group
              id="radio-style-options"
              v-model="corporateIdentity.buttons.layout.buttonStyle"
              :options="selectedButtonStyleOptions"
              :aria-describedby="ariaDescribedbyStyle"
              name="radio-style-options"
              @change="buttonShapeChanged($event, 'buttonStyle')"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WebFontLoader from '@/vendor/webfontloader';
import FontPicker from 'font-picker-vue';
import { isEmpty, cloneDeep } from 'lodash';

export default {
  name: 'FoldertSettingsCorporateIdentity',
  components: {
    ModalTypographySettings: () => import('@/components/modals/ModalTypographySettings.vue'),
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
    ColorPicker: () => import('@/components/helper/ColorPicker.vue'),
    FontPicker,
  },
  props: [
    'corporateIdentityData',
    'isSuperAdmin',
    'customGroupFonts',
  ],
  data: () => ({
    fontOne: '',
    fontTwo: '',
    logoFile: [],
    overrideAllCiChildren: false,
    toggleAllCiChildrenOverride: false,
    overrideChildrenCis: {
      overrideChildrenWithLogo: false,
      overrideChildrenWithFavicon: false,
      overrideChildrenWithTypo: false,
      overrideChildrenWithColor: false,
      overrideChildrenWithButton: false,
    },
    corporateIdentity: {
      logo: {
        image: {
          url: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
          width: null,
          height: null,
        },
      },
      favicon: {
        image: {
          url: null,
        },
      },
      _customColor: ['', '', '', ''],
      defaultFonts: {
        font1: {
          font: 'Open Sans',
          type: 'google',
        },
        font2: {},
      },
      enableExtendTypo: false,
      extendTypo: {
        h1: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '2.4rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '2.0rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '2.0rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h2: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '2rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.8rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.8rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h3: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1.5rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.3rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.3rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h4: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h5: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        h6: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        p: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
      },
      buttons: {
        layout: {
          buttonShape: 'round',
          buttonStyle: 'narrow',
        },
        styleObject: {
          borderRadius: '0.25rem',
          padding: '0.3125rem 1rem',
        },
      },
    },
    selectedButtonShape: '',
    selectedButtonShapeOptions: [
      { text: 'Round', value: 'round' },
      { text: 'Square', value: 'square' },
    ],
    selectedButtonStyle: '',
    selectedButtonStyleOptions: [
      { text: 'Narrow', value: 'narrow' },
      { text: 'Wide', value: 'wide' },
    ],
    typographyStyles: null,
    showTypographyModal: false,
    tagForTypography: null,
    googleFontFamily: null,
    devices: [
      'screen',
      'tablet',
      'mobile',
    ],
    typographyElements: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'],
    settingsCopied: false,
    settingsComponent: 42,
    fontOneSource: 'googleFonts',
    fontTwoSource: 'googleFonts',
    fontFamilyListSystem: [
      'Arial',
      'Arial Black',
      'Verdana',
      'Tahoma',
      'Trebuchet MS',
      'Impact',
      'Times New Roman',
      'Didot',
      'Georgia',
      'American Typewriter',
      'Andalé Mono',
      'Courier',
      'Lucida Console',
      'Monaco',
      'Bradley Hand',
      'Brush Script MT',
      'Luminari',
      'Comic Sans MS',
    ],
  }),
  created() {
    if (this.corporateIdentityData) {
      this.setupLogoAndFavicon();
      this.setupColors();
      this.setupTypography();
      this.setupExtendedTypo();
      this.setupButtons();
      if (localStorage.getItem('corp_id_settings')) {
        this.settingsCopied = true;
      }
    }
  },
  computed: {
    allChildrenCisArray() {
      return Object.entries(this.overrideChildrenCis).map(([handle]) => {
        const nameString = handle.replace(/(^overrideChildrenWith)/gi, '');
        return {
          name: nameString.toLowerCase(),
          handle,
        };
      });
    },
  },
  methods: {
    setupLogoAndFavicon() {
      if (this.corporateIdentityData.logo?.image) {
        this.corporateIdentity.logo.image = this.corporateIdentityData.logo.image;
      }
      if (this.corporateIdentity.logo?.image.url) {
        if (!this.$checkForCloudImage(this.corporateIdentity.logo.image.url)) {
          this.corporateIdentity.logo.image.url = this.$makeCloudImage(
            this.corporateIdentity.logo.image.url,
            { width: 450, height: 300 },
            this.corporateIdentity.logo.image,
          );
        }
      }
      this.corporateIdentity.favicon.image = (
        this.corporateIdentityData.favicon?.image
      )
        ? this.corporateIdentityData.favicon.image
        : this.corporateIdentity.favicon.image;
    },
    setupColors() {
      for (let i = 0; i < 4; i++) {
        // eslint-disable-next-line no-underscore-dangle
        this.corporateIdentity._customColor[i] = (
          this.corporateIdentityData._customColor
          && typeof this.corporateIdentityData._customColor[i] !== 'undefined'
        )
          ? this.corporateIdentityData._customColor[i]
          : '';
      }
    },
    setupTypography() {
      if (this.corporateIdentityData.defaultFonts) {
        if (this.corporateIdentityData.defaultFonts.font1) {
          this.corporateIdentity.defaultFonts.font1 = cloneDeep(
            this.corporateIdentityData.defaultFonts.font1,
          );
          this.fontOne = this.corporateIdentityData.defaultFonts.font1.font;
          switch (this.corporateIdentityData.defaultFonts.font1.type) {
            case 'system':
              this.fontOneSource = 'systemFonts';
              break;
            case 'custom':
              this.fontOneSource = 'customFonts';
              break;
            default:
              this.fontOneSource = 'googleFonts';
          }
        }
        if (this.corporateIdentityData.defaultFonts.font2
          && this.corporateIdentityData.defaultFonts.font2.font) {
          this.corporateIdentity.defaultFonts.font2 = cloneDeep(
            this.corporateIdentityData.defaultFonts.font2,
          );
          this.fontTwo = this.corporateIdentityData.defaultFonts.font2.font;
          switch (this.corporateIdentityData.defaultFonts.font2.type) {
            case 'system':
              this.fontTwoSource = 'systemFonts';
              break;
            case 'custom':
              this.fontTwoSource = 'customFonts';
              break;
            default:
              this.fontTwoSource = 'googleFonts';
          }
        }
      } else if (this.corporateIdentityData._customFonts) {
        // migration code: if the _customFonts exist and not the defaultFonts
        if (this.corporateIdentityData.disableGoogleFont) {
          this.corporateIdentity.defaultFonts.font1 = {
            font: 'Verdana',
            type: 'system',
          };
          this.corporateIdentity.defaultFonts.font2 = {};
          this.fontOneSource = 'systemFonts';
          this.fontOne = 'Verdana';
          this.fontTwoSource = 'systemFonts';
          this.fontTwo = '';
        } else {
          // eslint-disable-next-line max-len
          this.corporateIdentity.defaultFonts.font1 = (this.corporateIdentityData._customFonts.google[0])
            ? {
              font: this.corporateIdentityData._customFonts.google[0],
              type: 'google',
            }
            : {};
          // eslint-disable-next-line max-len
          this.corporateIdentity.defaultFonts.font2 = (this.corporateIdentityData._customFonts.google[1])
            ? {
              font: this.corporateIdentityData._customFonts.google[1],
              type: 'google',
            }
            : {};
          this.fontOneSource = 'googleFonts';
          this.fontOne = (this.corporateIdentityData._customFonts.google[0])
            ? this.corporateIdentityData._customFonts.google[0]
            : '';
          if (isEmpty(this.corporateIdentity.defaultFonts.font2)) {
            this.fontTwoSource = 'systemFonts';
            this.fontTwo = '';
          } else {
            this.fontTwoSource = 'googleFonts';
            this.fontTwo = (this.corporateIdentityData._customFonts.google[1])
              ? this.corporateIdentityData._customFonts.google[1]
              : '';
          }
        }
      }
      WebFontLoader.load({
        google: {
          families: [this.fontOne, this.fontTwo],
        },
      });
    },
    setupExtendedTypo() {
      this.corporateIdentity.enableExtendTypo = !!this.corporateIdentityData.enableExtendTypo;
      if (this.corporateIdentityData.extendTypo) {
        this.typographyElements.forEach((element) => {
          if (this.corporateIdentityData.extendTypo[element]) {
            // eslint-disable-next-line max-len
            this.corporateIdentity.extendTypo[element].fontFamily = this.corporateIdentityData.extendTypo[element].fontFamily;
            this.devices.forEach((device) => {
              this.corporateIdentity.extendTypo[element][device] = {
                fontSize: this.corporateIdentityData.extendTypo[element][device].fontSize
                  || this.corporateIdentity.extendTypo[element].fontSize,
                lineHeight: this.corporateIdentityData.extendTypo[element][device].lineHeight
                  || this.corporateIdentity.extendTypo[element][device].lineHeight,
                fontWeight: this.corporateIdentityData.extendTypo[element][device].fontWeight
                  || this.corporateIdentity.extendTypo[element][device].fontWeight,
                letterSpacing: this.corporateIdentityData.extendTypo[element][device].letterSpacing
                  || this.corporateIdentity.extendTypo[element][device].letterSpacing,
              };
            });
          }
        });
      }
    },
    setupButtons() {
      if (this.corporateIdentityData.buttons) {
        // eslint-disable-next-line max-len
        this.corporateIdentity.buttons.layout.buttonShape = this.corporateIdentityData.buttons.layout.buttonShape;
        // eslint-disable-next-line max-len
        this.corporateIdentity.buttons.layout.buttonStyle = this.corporateIdentityData.buttons.layout.buttonStyle;
        this.corporateIdentity.buttons.styleObject = this.corporateIdentityData.buttons.styleObject;
      }
    },
    openTypographyModal(tag) {
      this.typographyStyles = this.corporateIdentity.extendTypo[tag];
      this.tagForTypography = tag;
      this.showTypographyModal = true;
    },
    updateStyles(styles, tag) {
      this.showTypographyModal = false;
      this.tagForTypography = null;
      if (styles !== false) {
        // update the ci settings and emit
        this.corporateIdentity.extendTypo[tag] = styles;
        this.$emit('update-ci-settings', this.corporateIdentity);
      } else {
        console.log('cancel modal');
      }
    },
    buttonShapeChanged(value, type) {
      this.corporateIdentity.buttons.layout[type] = value;
      if (type === 'buttonStyle' && value === 'wide') {
        this.corporateIdentity.buttons.styleObject.padding = '0.5rem 1.6rem';
      } else if (type === 'buttonStyle' && value === 'narrow') {
        this.corporateIdentity.buttons.styleObject.padding = '0.3125rem 1rem';
      } else if (type === 'buttonShape' && value === 'round') {
        this.corporateIdentity.buttons.styleObject.borderRadius = '0.25rem';
      } else if (type === 'buttonShape' && value === 'square') {
        this.corporateIdentity.buttons.styleObject.borderRadius = '0';
      }
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateUploadImageObject(imageObject) {
      if (imageObject) {
        this.corporateIdentity.logo = { image: imageObject };
      } else {
        this.corporateIdentity.logo = { image: { url: null } };
      }
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateUploadFaviconImageObject(imageObject) {
      this.corporateIdentity.favicon.image = (imageObject === null)
        ? { url: null }
        : imageObject;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateBaseColor(newColor) {
      this.corporateIdentity._customColor[0] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateSecondColor(newColor) {
      this.corporateIdentity._customColor[1] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateThirdColor(newColor) {
      this.corporateIdentity._customColor[2] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateFourthColor(newColor) {
      this.corporateIdentity._customColor[3] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    enableDisableExtendTypo(event) {
      if (event === true) {
        console.log('typo enabled');
        // change the font to the first font
        const typographyElement = this.corporateIdentity.extendTypo;
        Object.keys(typographyElement).forEach((element) => {
          this.corporateIdentity.extendTypo[element].fontFamily = this.fontOne;
        });
      } else {
        console.log('typo disabled');
      }
      this.corporateIdentity.enableExtendTypo = event;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    changeFont(event, fontNr) {
      const fontId = `font${fontNr}`;
      const fontNumber = (fontNr === 1) ? 'fontOne' : 'fontTwo';
      const fontSource = (fontNr === 1) ? 'fontOneSource' : 'fontTwoSource';
      let oldFont = '';
      if (!this.corporateIdentityData.defaultFonts) {
        const googleFontIndex = fontNr - 1;
        if (this.corporateIdentityData.disableGoogleFont
          && this.corporateIdentity._customFonts) {
          oldFont = this.corporateIdentity._customFonts.google[googleFontIndex];
        }
      } else {
        oldFont = this.corporateIdentityData.defaultFonts[fontId].font;
      }
      let newFont = '';
      let type = '';
      switch (this[fontSource]) {
        case 'systemFonts':
          newFont = event;
          type = 'system';
          break;
        case 'customFonts':
          newFont = event;
          type = 'custom';
          break;
        default:
          newFont = event.family;
          type = 'google';
          WebFontLoader.load({
            google: {
              families: [newFont],
            },
          });
      }
      const typographyElement = this.corporateIdentity.extendTypo;
      if (
        oldFont
        // eslint-disable-next-line max-len
        && this.corporateIdentity.defaultFonts.font1.font !== this.corporateIdentity.defaultFonts.font2?.font
        && this.corporateIdentity.enableExtendTypo === true
        && JSON.stringify(typographyElement).search(oldFont) !== -1) {
        this.showConfirmBox(oldFont, newFont, type, fontNr);
      } else {
        this.corporateIdentity.defaultFonts[fontId] = {
          font: newFont,
          type,
        };
        this[fontNumber] = newFont;
        this.$emit('update-ci-settings', this.corporateIdentity);
      }
    },
    changeFontSource(source, fontNr) {
      const fontId = `font${fontNr}`;
      const fontNumber = (fontNr === 1) ? 'fontOne' : 'fontTwo';
      let oldFont = '';
      if (!this.corporateIdentityData.defaultFonts) {
        const googleFontIndex = fontNr - 1;
        if (this.corporateIdentityData.disableGoogleFont
          && this.corporateIdentity._customFonts) {
          oldFont = this.corporateIdentity._customFonts.google[googleFontIndex];
        }
      } else {
        oldFont = this.corporateIdentityData.defaultFonts[fontId].font;
      }
      // const oldFont = this.corporateIdentityData.defaultFonts[fontId].font;
      let newFont = '';
      let type = '';
      if (source === 'customFonts') {
        type = 'custom';
        [newFont] = this.customGroupFonts;
      } else if (source === 'systemFonts') {
        type = 'system';
        newFont = 'Verdana';
      } else {
        type = 'google';
        newFont = 'Open Sans';
        WebFontLoader.load({
          google: {
            families: [newFont],
          },
        });
      }
      const typographyElement = this.corporateIdentity.extendTypo;
      if (oldFont
        // eslint-disable-next-line max-len
        && this.corporateIdentity.defaultFonts.font1.font !== this.corporateIdentity.defaultFonts.font2?.font
        && this.corporateIdentity.enableExtendTypo === true
        && JSON.stringify(typographyElement).search(oldFont) !== -1) {
        this.showConfirmBox(oldFont, newFont, type, fontNr);
      } else {
        this[fontNumber] = newFont;
        this.corporateIdentity.defaultFonts[fontId] = {
          font: newFont,
          type,
        };
        this.$emit('update-ci-settings', this.corporateIdentity);
      }
    },
    familyOfExtendedTypoTag(tag) {
      return this.corporateIdentity.extendTypo[tag].fontFamily;
    },
    toggleOverrideAllChildrenBox(value) {
      this.$root.$emit('bv::toggle::collapse', 'collapse-override-ci-children');
      if (!value) {
        Object.entries(this.overrideChildrenCis).forEach(([key]) => {
          this.overrideChildrenCis[key] = false;
        });
        this.overrideAllCiChildren = false;
        this.$emit('override-all-children', false);
      }
    },
    overrideAllChildren(value) {
      if (value) {
        Object.entries(this.overrideChildrenCis).forEach(([key]) => {
          this.overrideChildrenCis[key] = true;
        });
      } else {
        Object.entries(this.overrideChildrenCis).forEach(([key]) => {
          this.overrideChildrenCis[key] = false;
        });
      }
      this.$emit('override-all-children', value);
    },
    changeOverrideChildren(name, value) {
      console.log('changed', name, value);
      this.$emit('override-children', name, value);
    },
    showConfirmBox(oldFont, newFont, newFontType, fontNr) {
      const h = this.$createElement;
      const titleVNode = h('div', { domProps: { innerHTML: this.$t('settings.project.corporateIdentity.typography.confirmTitle') } });
      const messageVNode = h('div', {
        class: ['text-center', 'p-4'],
        domProps: { innerHTML: this.$t('settings.project.corporateIdentity.typography.confirmContent') },
      });
      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('buttons.ok'),
        cancelTitle: this.$t('buttons.cancel'),
        footerClass: 'flex-nowrap',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          const fontId = `font${fontNr}`;
          const fontNumber = (fontNr === 1) ? 'fontOne' : 'fontTwo';
          if (value) {
            WebFontLoader.load({
              google: {
                families: [newFont],
              },
            });
            const typographyElement = this.corporateIdentity.extendTypo;
            Object.keys(typographyElement).forEach((element) => {
              // check if the old font exist in the array
              if (typographyElement[element].fontFamily === oldFont) {
                this.corporateIdentity.extendTypo[element].fontFamily = newFont;
              }
            });
            this[fontNumber] = newFont;
            this.corporateIdentity.defaultFonts[fontId] = {
              font: newFont,
              type: newFontType,
            };
          } else {
            // cancel the modal reset the radio button and reset the dropdown
            const oldType = this.corporateIdentity.defaultFonts[fontId].type;
            this[fontNumber] = oldFont;
            switch (oldType) {
              case 'system':
                this.fontOneSource = 'systemFonts';

                break;
              case 'custom':
                this.fontOneSource = 'customFonts';
                break;
              default:
                this.fontOneSource = 'googleFonts';
            }
          }
          this.$emit('update-ci-settings', this.corporateIdentity);
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    copySettings() {
      localStorage.setItem('corp_id_settings', JSON.stringify(this.corporateIdentity));
      this.settingsCopied = true;
    },
    pasteSettings() {
      const newSettings = localStorage.getItem('corp_id_settings');
      this.corporateIdentity = JSON.parse(newSettings);
      if (this.corporateIdentity._customFonts.google[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        this.fontOne = this.corporateIdentity._customFonts.google[0].split(':')[0];
      }
      if (this.corporateIdentity._customFonts.google[1] !== '') {
        // eslint-disable-next-line prefer-destructuring
        this.fontTwo = this.corporateIdentity._customFonts.google[1].split(':')[0];
      }
      this.settingsComponent += 1;
      localStorage.removeItem('corp_id_settings');
      this.settingsCopied = false;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
  },
};
</script>

<style scoped lang="scss">
.override-children {
  border: 1px solid #bbb;
  h4 {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  &.expanded {
    border: 1px solid #A05B5B;
    h4 {
      background-color: #A05B5B;
      color: #fff;
    }
  }

  ul {
    list-style: none;
  }
}
.verte {
  justify-content: flex-start;
}

#font-picker-one {
  z-index: 3;
}

#font-picker-two {
  z-index: 2;
}

#app #content .typography-box {
  h3 {
    color: $settings-form-text;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    cursor: pointer;

    span {
      svg {
        position: relative;
        top: 1px;
      }
    }
  }
}

/deep/ .modal-dialog .modal-footer > button {
  display: block;
  width: 100%;

  &:first-child {
    border-right: 1px solid #e0e2e4 !important;
  }
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
  }
}
</style>
